// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

// @import "~bootstrap/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

$tuskerr-black: rgba(0, 0, 0, 1);
$tuskerr-white: rgb(255, 255, 255);
$tuskerr-red: rgba(216, 39, 100, 1);
$tuskerr-darkred: rgba(114, 30, 77, 1);
$tuskerr-yellow: rgba(242, 189, 83, 1);
$tuskerr-orange: rgba(231, 132, 78, 1);
$tuskerr-blue: rgba(100, 191, 191, 1);
$tuskerr-darkblue: rgba(10,35,73,1);

$primary: $tuskerr-darkred;
$secondary: $tuskerr-blue;
$tertiary: $tuskerr-orange;
$danger: $tuskerr-red;


// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

$offcanvas-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);

// Customize some defaults
$body-color: $tuskerr-black;
$body-bg: $tuskerr-white;
$border-radius: .4rem;
$success: #7952b3;


// Required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";

@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar"; // Requires nav
@import "~bootstrap/scss/card";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/accordion";
// @import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal"; // Requires transitions
// @import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
// @import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas"; // Requires transitions
// @import "~bootstrap/scss/placeholders";

// Helpers
// @import "helpers";

// Utilities
// @import "~bootstrap/scss/utilities/api";


//
// Custom styles
//

// @import "icon-list";

body {
  padding: 0;
  font-family: filson-soft, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: $tuskerr-darkblue;
  margin: -20px 0;
}

header {
  margin-top: -20px;
  background-color: $tuskerr-darkred;
  padding: 0;
  margin: 0;
  h2 {
    text-align: center;
    color: $tuskerr-darkred;
  }
}
#subheader {
  text-align: center;
}
#main-header{
  background-color: $tuskerr-darkred;
}
h4 {
  color: $tuskerr-darkred;
}
#header-logo {
  max-width: 320px;
  margin: 20px auto;
  display: block;
}

.big3 {
  padding: 160px 0;
  margin: 10px;
  h2 {
    text-align: center;
    padding: 40px;
    max-width: 800px;
    margin: 0 auto;
  }
  p {
    text-align: center;
    font-size: 1rem;
    color: $tuskerr-black;
    padding: 40px;
  }
}
#hook1 {
  color: $tuskerr-darkred;
  background-color: $tuskerr-yellow;
}

#hook2 {
  color: $tuskerr-blue;
  background-color: $tuskerr-red;
}

#hook3 {
  color: $tuskerr-darkred;
  background-color: $tuskerr-blue;
}

#intrigued-row {
  padding: 30px;
  max-width: 800px;
  margin: 0 auto;
  #intrigued-right {
    -ms-flex-align: stretch;
  }
  h4, p {
    padding: 20px;
    display: block;
  }
  h4 {
    width: 300px;
    font-size: 1.3rem;
    color: $tuskerr-darkred;
  }
  p {
    font-size: 1rem;
    color: $tuskerr-darkblue;
  }
}

#contact-section {
  background-color: $tuskerr-blue;
  width: 100%;
  padding: 15px;
  form {
    color: $tuskerr-darkblue;
    margin: 30px auto;
      input, select {
      border-radius: 2px;
      margin-top: 20px;
    }
  
  }
  h5 {
    color: $tuskerr-darkblue;
    padding: 30px;
    max-width: 1000px;
    margin: 50px auto 0;
  }
  }
